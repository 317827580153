import { DashboardCustomize } from "@mui/icons-material";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  de: {
    translation: {
      Menu: "Speisekarte",
      Invoices: "Rechnungen",
      Holder: "Menü Halter",
      Profile: "Impressum",
      Category: "Kategorie",
      Categories: "Kategorien",
      Image: "Bild",
      Title: "Titel",
      Product: "Produkt",
      Products: "Speisen & Getränke",
      Active: "Aktiv",
      Edit: "Bearbeiten",
      Settings: "Logo und Farben",
      CreateCategory: "Kategorie erstellen",
      EditCategory: "Kategorie bearbeiten",
      CategorySaved: "Kategorie gespeichert",
      CategoryDeleted: "Kategorie gelöscht",
      CategoryCreated: "Kategorie erstellt",
      Save: "Speichern",
      SaveAndNew: "Speichern und weiter",
      UploadImage: "Bild hochladen",
      UploadPdf: "PDF Datei hochladen",
      UploadFile: "Datei hochladen",
      MenuColor: "Menü Farbe",
      CategoryColor: "Kategorie Farbe",
      TextColor: "Text Farbe",
      Font: "Schriftart",
      Date: "Datum",
      Amount: "Betrag",
      EditInvoiceData: "Rechnungs- / Impressumdaten bearbeiten",
      InvoiceDataRefreshed: "Rechnungs- / Impressum daten aktualisiert",
      PasswordChange: "Passwort ändern",
      PasswordRefreshed: "Passwort aktualisiert",
      Days: "Tage",
      Remaining: "verbleibend",
      Scans: "Aufrufe 30 Tage",
      DownloadFormats: "Als SVG, PNG herunterladen",
      YourCode: "Hi, dein QR Code",
      Description: "Beschreibung",
      Price: "Preis",
      SubTitle: "Untertitel",
      Columns: "Spalten",
      Rows: "Zeilen",
      CreateDesign: "Design erstellen",
      BackgroundImageOptional: "Hintergrund Bild (optional)",
      DesignTemplates: "Design vorlagen",
      SavedDesigns: "Gespeicherte Designs",
      Monthly: "Monatlich",
      Yearly: "Jährlich",
      PerMonth: "Pro Monat",
      Subscribe: "Zum Bezahlvorgang",
      Featured: "Beliebt",
      InvoiceName: "Geschäftsführer",
      Street: "Straße",
      ZipCode: "PLZ",
      City: "Stadt",
      InvoiceEmail: "Rechnungs - Email",
      NewPassword: "Neues Passwort",
      Delete: "Löschen",
      SaveButton: "Jetzt 20 % sichern",
      DesignCreated: "Design erstellt",
      Create: "Erstellen",
      DownloadDesign: "Design herunterladen",
      Ended: "abgelaufen",
      Subscription: "Abo",
      ProductCreated: "Produkt erstellt",
      CreateProduct: "Produkt erstellen",
      ProductEdited: "Produkt bearbeitet",
      ProductDeleted: "Produkt gelöscht",
      EditProduct: "Produkt bearbeiten",
      SettingsRefreshed: "Einstellungen aktualisiert",
      EditMenuLayout: "Menü Layout bearbeiten",
      EditSettings: "Einstellungen bearbeiten",
      EditLayout: "Layout bearbeiten",
      Left: "Links",
      Right: "Rechts",
      Currency: "Währung",
      CurrencyPosition: "Währungsposition",
      PriceCurrencyIcon: "€",
      DecimalSymbol: "Dezimalzeichen",
      SubscriptionFeaturesFreePdf: "Kostenlos, Unbegrenzte Scans, Teilweise mit Werbeeinbledungen, Jederzeit änderbare Pdf Speisekarte, Eigenes Logo, Social Media Links (Facebook, Instagram, TikTok, Google), Rechtssicheres Impressum, Statistiken",
      SubscriptionFeaturesPdf: "Ohne Werbung, Unbegrenzte Scans, Jederzeit änderbare Pdf Speisekarte, Eigenes Logo, Social Media Links (Facebook, Instagram, TikTok, Google), Rechtssicheres Impressum, Statistiken, jährliche Zahlung",
      SubscriptionFeatures: "Digitale Speisekarte für 12 Monate, unbegrenzte Speisen und Getränke, Individuelle Speisekarten Designs, Social Media Links, unbegrenzte Änderungen, 24 / 7 Chat Support, Allergene und Zusatzstoffe, QR Code Designs, inkl. USt.",
      PerMonth: "Pro Monat",
      PerYear: "Pro Jahr",
      OneTime: "100 Scans",
      MONTHLY: "Monatlich",
      YEARLY: "Jährlich",
      YEARLY_PDF: "Jährlich",
      ONETIME: "EINMALIG",
      FREE: "Kostenlos",
      Pay: "Zum Bezahlvorgang",
      MandateCreated: "Vielen Dank für Ihr Abo. Bei erfolgreicher Zahlung erhalten Sie eine Rechnung per E-Mail",
      MandateError: "Zahlungsmethode konnte nicht verarbeitet werden, bitte versuchen Sie es erneut",
      SocialLinkFacebook: "Facebook Link",
      SocialLinkInstagram: "Instagram Link",
      SocialLinkGoogle: "Google Link",
      SocialLinkTikTok: "TikTok Link",
      QrCodeLink: "QR Code Link",
      CompanyName: "Firmenbezeichnung",
      Template: "Layout auswählen",
      Additives: "Zusatzstoffe",
      Allergens: "Allergene",
      HolderDesigns: "Drucken",
      AllergensEdited: "Allergene aktualisiert",
      AdditivesEdited: "Zusatzstoffe aktualisiert",
      NoElements: "Keine Elemente",
      SubscriptionError: "Bitte vervollständigen Sie das Impressum",
      SubscriptionInfo: "Sie erhalten separat eine E-Mail mit weiteren Details",
      FeedbackInfo: "Vielen Dank für das Feedback, Sie erhalten per E-Mail ein Update",
      Priority: "Priorität",
      Content: "Nachricht",
      Send: "Senden",
      PriorityWish: "Wunsch",
      PriorityMiddle: "Mittel",
      PriorityImportant: "Wichtig",
      PriorityCritical: "Kritisch",
      Service: "Einrichtungsservice",
      ServiceError: "Bitte vervollständigen Sie das Impressum",
      ServiceInfo: "Sie erhalten separat eine E-Mail mit weiteren Details",
      SetupService: "Einrichtungsservice",
      SetupServiceRequest: "Absenden",
      SetupServiceDetails: "Wir erstellen Ihre digitale Speisekarte, Sie müssen lediglich die Bilder Ihrer aktuellen Speisekarte hochladen.",
      SetupServicePending: "Wir haben Ihre Speisekarte erhalten, die Einrichtung kann bis zu 24 Stunden dauern. Sie erhalten eine E-Mail sobald Ihre QR Code Speisekarte fertig ist.",
      SetupServiceFinished: "Ihre Speisekarte wurde erfolgreich eingerichtet.",
      Templates: "Layout auswählen",
      Activate: "Aktivieren",
      TemplateActivated: "Das Template wurde aktiviert. Um die Änderungen zu sehen scannen Sie den QR Code erneut.",
      Login: "Anmelden",
      PasswordForgotten: "Passwort vergessen",
      Password: "Passwort",
      NoAccount: "Noch kein Account?",
      Welcome: "Willkommen bei Menuemen",
      PasswordReset: "Passwort zurücksetzen",
      BackToLogin: "Zurück zum Login",
      PasswordResetInfo: "Sofern wir die E-Mail Adresse finden bekommst Du ein Link mit den neuen Zugangsdaten.",
      DescriptionKey: "Kürzel",
      DescriptionText: "Beschreibung",
      OpenMenu: "Vorschau",
      Copy: "Kopieren",
      ProductCopied: "Produkt kopiert",
      Inactive: "Deaktiviert",
      FreeLimited: "Kostenlos",
      EditTemplate: "Template bearbeiten",
      VideoUploading: "Video wird hochgeladen...",
      VideoUploaded: "Video wird gleich in der Vorschau angezeigt",
      TemplateEdited: "Template gespeichert",
      SignUp: "Kostenlos starten",
      AlreadyAccount: "Du hast bereits ein Account?",
      CreateAccount: "Registrieren",
      SocialMedia: "Social Media",
      StartChat: "Chat starten",
      DescriptionChat: "Bei Fragen und Anmerkungen öffnen Sie einfach den Chat unten rechts.",
      SetupServiceCard: "Erstellen lassen",
      GetStarted: "Los legen",
      ServiceHide: "Hinweis ausblenden",
      SetupServiceCheckoutInfo: "Fügen Sie beim Bezahlvorgang das Produkt 'Einrichtungsservice' zum Warenkorb hinzu.",
      HidePrice: "Preis ausblenden",
      PdfUpdated: "PDF Datei erfolgreich aktualisiert",
      Languages: "Sprachen",
      TrialVersion: "Menuemen Freischalten",
      TrialVersionDetails: "Aktivieren Sie Menuemen ganz einfach mit Ihrer beliebigen Zahlungsmethode.",
      TrialMoreDetails: "Details",
      DiscountMenu: "20 € Rabatt",
      DiscountTitle: "Jetzt Rabatt sichern",
      DiscountDescription: "Sichern Sie sich 20 € Rabatt auf den Jahrespreis. Nur noch 3 Tage gültig.",
      DiscountButton: "Jetzt abonnieren"
    },
  },
  es: {
    translation: {
      Menu: "Menü",
      Invoices: "Facturas",
      Holder: "Portador",
      Profile: "Perfil",
      Category: "Categoría",
      Categories: "Categorías",
      Image: "Imagen",
      Title: "Título",
      Product: "Producto",
      Products: "Productos",
      Active: "Activo",
      Edit: "Editar",
      Settings: "Configuración",
      CreateCategory: "Crear categoría",
      EditCategory: "Editar categoría",
      CategorySaved: "Categoría guardada",
      CategoryDeleted: "Categoría eliminada",
      CategoryCreated: "Categoría creada",
      Save: "Guardar",
      SaveAndNew: "Guardar y nuevo",
      UploadImage: "Subir imagen",
      UploadFile: "Subir archivo",
      MenuColor: "Color del menú",
      CategoryColor: "Color de la categoría",
      TextColor: "Color del texto",
      Font: "Fuente",
      Date: "Fecha",
      Amount: "Cantidad",
      EditInvoiceData: "Editar datos de la factura / perfil",
      InvoiceDataRefreshed: "Datos de la factura / perfil actualizados",
      PasswordChange: "Cambiar contraseña",
      PasswordRefreshed: "Contraseña actualizada",
      Days: "Días",
      Remaining: "Restante",
      Scans: "Escaneos en 30 días",
      DownloadFormats: "Descargar como SVG, PNG",
      YourCode: "Hola, tu código QR",
      Description: "Descripción",
      Price: "Precio",
      SubTitle: "Subtítulo",
      Columns: "Columnas",
      Rows: "Filas",
      CreateDesign: "Crear diseño",
      BackgroundImageOptional: "Imagen de fondo (opcional)",
      DesignTemplates: "Plantillas de diseño",
      SavedDesigns: "Diseños guardados",
      Monthly: "Mensual",
      Yearly: "Anual",
      PerMonth: "Por mes",
      Subscribe: "Suscribirse",
      Featured: "Destacado",
      InvoiceName: "Nombre del titular",
      Street: "Calle",
      ZipCode: "Código Postal",
      City: "Ciudad",
      InvoiceEmail: "Email de la factura",
      NewPassword: "Nueva contraseña",
      Delete: "Eliminar",
      SaveButton: "Ahorra un 20% ahora",
      DesignCreated: "Diseño creado",
      Create: "Crear",
      DownloadDesign: "Descargar diseño",
      Ended: "Finalizado",
      Subscription: "Suscripción",
      ProductCreated: "Producto creado",
      CreateProduct: "Crear producto",
      ProductEdited: "Producto editado",
      ProductDeleted: "Producto eliminado",
      EditProduct: "Editar producto",
      SettingsRefreshed: "Configuración actualizada",
      EditMenuLayout: "Editar diseño del menú",
      EditSettings: "Editar configuración",
      EditLayout: "Editar diseño",
      Left: "Izquierda",
      Right: "Derecha",
      Currency: "Moneda",
      CurrencyPosition: "Posición de la moneda",
      PriceCurrencyIcon: "€",
      DecimalSymbol: "Símbolo decimal",
      SubscriptionFeatures: "Menú digital por 12 meses, servicio de configuración gratuito, logotipo, productos y bebidas ilimitados, diseños de menú personalizados, enlaces a redes sociales, cambios ilimitados, soporte de chat 24/7, alérgenos y aditivos, diseños de códigos QR",
      PerMonth: "Por mes",
      PerYear: "Por año",
      OneTime: "100 escaneos",
      MONTHLY: "Mensual",
      YEARLY: "Anualmente",
      ONETIME: "ÚNICA VEZ",
      FREE: "Gratis",
      Pay: "Ir al proceso de pago",
      MandateCreated: "Gracias por tu suscripción. Recibirás una factura por correo electrónico una vez realizado el pago.",
      MandateError: "No se pudo procesar el método de pago. Por favor, inténtalo de nuevo.",
      SocialLinkFacebook: "Enlace de Facebook",
      SocialLinkInstagram: "Enlace de Instagram",
      SocialLinkGoogle: "Enlace de Google",
      QrCodeLink: "Enlace del código QR",
      CompanyName: "Nombre de la empresa",
      Template: "Seleccionar diseño",
      Additives: "Aditivos",
      Allergens: "Alérgenos",
      HolderDesigns: "Imprimir",
      AllergensEdited: "Alérgenos actualizados",
      AdditivesEdited: "Aditivos actualizados",
      NoElements: "Sin elementos",
      SubscriptionError: "Por favor, completa los datos del perfil",
      SubscriptionInfo: "Recibirás un correo electrónico separado con más detalles",
      FeedbackInfo: "Gracias por tu feedback. Recibirás una actualización por correo electrónico.",
      Priority: "Prioridad",
      Content: "Mensaje",
      Send: "Enviar",
      PriorityWish: "Deseo",
      PriorityMiddle: "Medio",
      PriorityImportant: "Importante",
      PriorityCritical: "Crítico",
      Service: "Servicio de configuració",
      ServiceError: "Por favor, completa los datos del perfil",
      ServiceInfo: "Recibirás un correo electrónico separado con más detalles",
      SetupService: "Configuración del servicio",
      SetupServiceRequest: "Enviar",
      SetupServiceDetails: "Crearemos tu menú digital, solo tienes que subir las imágenes de tu menú actual.",
      SetupServicePending: "Hemos recibido tu menú. La configuración puede tardar hasta 24 horas. Recibirás un correo electrónico cuando tu menú QR esté listo.",
      SetupServiceFinished: "Tu menú ha sido configurado correctamente.",
      Templates: "Seleccionar diseño",
      Activate: "Activar",
      TemplateActivated: "El diseño ha sido activado. Para ver los cambios, escanea el código QR nuevamente.",
      Login: "Iniciar sesión",
      PasswordForgotten: "¿Olvidaste tu contraseña?",
      Password: "Contraseña",
      NoAccount: "¿Aún no tienes una cuenta?",
      Welcome: "Bienvenido",
      PasswordReset: "Restablecer contraseña",
      BackToLogin: "Volver al inicio de sesión",
      PasswordResetInfo: "Si encontramos la dirección de correo electrónico, recibirás un enlace con las nuevas credenciales de acceso.",
      DescriptionKey: "Clave de descripción",
      DescriptionText: "Descripción",
      OpenMenu: "Abrir menú",
      Copy: "Copiar",
      ProductCopied: "Producto copiado",
      Inactive: "Inactivo",
      FreeLimited: "Gratis",
      EditTemplate: "Editar plantilla",
      VideoUploading: "Subiendo video...",
      VideoUploaded: "El video se mostrará en la vista previa en breve",
      TemplateEdited: "Plantilla guardada",
      SignUp: "Registrarse",
      AlreadyAccount: "¿Ya tienes una cuenta?",
      CreateAccount: "Crear cuenta",
      SocialMedia: "Redes sociales",
      StartChat: "Iniciar chat",
      DescriptionChat: "Si tienes preguntas o comentarios, simplemente abre el chat en la esquina inferior derecha.",
      SetupServiceCard: "Crear",
      GetStarted: "Comenzar",
      ServiceHide: "Ocultar nota",
      SetupServiceCheckoutInfo: "Agrega el producto 'Servicio de configuración' al carrito durante el proceso de pago.",
      HidePrice: "Ocultar precio",
      TrialVersion: "Menuemen Desbloquear",
      TrialVersionDetails: "Sólo tiene que activar Menuemen con su método de pago favorito.",
    },
  },
  en: {
    translation: {
      Menu: "Menu",
      Invoices: "Invoices",
      Holder: "Menu Holder",
      Profile: "Profile",
      Category: "Category",
      Categories: "Categories",
      Image: "Image",
      Title: "Title",
      Product: "Product",
      Products: "Products",
      Active: "Active",
      Edit: "Edit",
      CreateCategory: "Create category",
      EditCategory: "Edit category",
      CategorySaved: "Category saved",
      CategoryDeleted: "Category deleted",
      CategoryCreated: "Category created",
      Save: "Save",
      SaveAndNew: "Save and continue",
      UploadImage: "Upload image",
      UploadFile: "Upload file",
      MenuColor: "Menu color",
      CategoryColor: "Category color",
      TextColor: "Text color",
      Font: "Font Style",
      Date: "Date",
      Amount: "Amount",
      EditInvoiceData: "Edit invoice data",
      InvoiceDataRefreshed: "Invoice data refreshed",
      PasswordChange: "Change password",
      PasswordRefreshed: "Password refreshed",
      Days: "Days",
      Remaining: "remaining",
      Scans: "Scans 30 days",
      DownloadFormats: "Save as SVG, PNG",
      YourCode: "Hi, your QR Code",
      Description: "Description",
      Price: "Price",
      SubTitle: "Subtitle",
      Columns: "Columns",
      Rows: "Rows",
      CreateDesign: "Create design",
      BackgroundImageOptional: "Background image (optional)",
      DesignTemplates: "Design templates",
      SavedDesigns: "Saved Designs",
      Monthly: "Monthly",
      Yearly: "Yearly",
      PerMonth: "Per month",
      SubscriptionFeatures: "Digital menu for 12 months, unlimited food and drinks, customized menu designs, social media links, unlimited changes, 24/7 chat support, allergens and additives, QR code designs, incl. VAT.",
      Subscribe: "Checkout",
      Featured: "Save",
      InvoiceName: "CEO Name",
      Street: "Street",
      ZipCode: "Zip Code",
      City: "City",
      InvoiceEmail: "Invoice - Email",
      NewPassword: "New Password",
      Delete: "Delete",
      SaveButton: "Save now 20 %",
      DesignCreated: "Design created",
      Create: "Create",
      DownloadDesign: "Download design",
      Ended: "ended",
      Subscription: "Subscription",
      ProductCreated: "Product created",
      CreateProduct: "Create product",
      ProductEdited: "Product edited",
      ProductDeleted: "Product deleted",
      EditProduct: "Edit product",
      SettingsRefreshed: "Settings refreshed",
      EditMenuLayout: "Edit menu layout",
      EditSettings: "Edit settings",
      EditLayout: "Edit layout",
      Left: "Left",
      Right: "Right",
      Currency: "Currency",
      CurrencyPosition: "Currency position",
      PriceCurrencyIcon: "$",
      DecimalSymbol: "Decimal symbol",
      PerMonth: "Per Month",
      PerYear: "Per Year",
      OneTime: "100 scans per month",
      MONTHLY: "Monthly",
      YEARLY: "Yearly",
      ONETIME: "One time",
      Pay: "Checkout",
      MandateCreated: "Payment mandate created",
      MandateError: "Payment mandate error, please try again",
      SocialLinkFacebook: "Facebook Link",
      SocialLinkInstagram: "Instagram Link",
      SocialLinkGoogle: "Google Link",
      QrCodeLink: "QR Code Link",
      CompanyName: "Company Name",
      Template: "Templates",
      HolderDesigns: "Print Designs",
      AllergensEdited: "Allergens edited",
      AdditivesEdited: "Additives edited",
      NoElements: "No elements",
      SubscriptionError: "Profile info not completed",
      SubscriptionInfo: "We'll send details about payment via e-mail",
      FeedbackInfo: "Thank you for feedback, you'll receive an update via e-mail",
      Priority: "Priority",
      Content: "Message",
      Send: "Send",
      PriorityWish: "Wish",
      PriorityMiddle: "Middle",
      PriorityImportant: "Important",
      PriorityCritical: "Critical",
      ServiceError: "Profile info not completed",
      ServiceInfo: "We'll send details about setup service via e-mail",
      SetupService: "Setup Service",
      SetupServiceRequest: "Request",
      SetupServiceDetails: "We'll create your own digital menu card from your printed design, just upload your files.",
      SetupServicePending: "We've received your files, the setup for the new qr code menu could take up to 24 hours. You'll receive an email notification when finished.",
      Templates: "Templates",
      Activate: "Activate",
      TemplateActivated: "Template activated, to preview the new template scan the qr code again",
      SetupServiceDetails: "We'll create your own digital menu card from your printed design, just upload your files.",
      Login: "Login",
      PasswordForgotten: "Password reset",
      Password: "Password",
      NoAccount: "No account?",
      Welcome: "Welcome to Menuemen",
      CreateAccount: "Register here",
      PasswordReset: "Reset password",
      BackToLogin: "Back to login",
      PasswordResetInfo: "You'll receive an email within 2 minutes if an account exists",
      DescriptionKey: "Key",
      DescriptionText: "Description",
      OpenMenu: "Preview",
      Copy: "Copy",
      ProductCopied: "Product copied",
      Inactive: "Inactive",
      FreeLimited: "Free",
      StartChat: "Open chat",
      DescriptionChat: "If you have any questions please oben chat on the right bottom corner.",
      SetupServiceCard: "Save time",
      GetStarted: "Get started",
      ServiceHide: "Hide this",
      SetupServiceCheckoutInfo: "During checkout please add 'Setupservice' to your basket.",
      HidePrice: "Hide price",
      TrialVersion: "Unlock Menuemen",
      TrialVersionDetails: "Simply activate Menuemen with your preferred payment method.",
      TrialMoreDetails: "More Details"
    },
  },
  tr: {
    translation: {
      Menu: "Menü",
      Invoices: "Faturalar",
      Holder: "Menü Tutucu",
      Profile: "Hakkımızda",
      Category: "Kategori",
      Categories: "Kategoriler",
      Image: "Resim",
      Title: "Başlık",
      Product: "Ürün",
      Products: "Yemekler ve İçecekler",
      Active: "Aktif",
      Edit: "Düzenle",
      Settings: "Logo ve Renkler",
      CreateCategory: "Kategori Oluştur",
      EditCategory: "Kategori Düzenle",
      CategorySaved: "Kategori Kaydedildi",
      CategoryDeleted: "Kategori Silindi",
      CategoryCreated: "Kategori Oluşturuldu",
      Save: "Kaydet",
      SaveAndNew: "Kaydet ve Yeni",
      UploadImage: "Resim Yükle",
      UploadFile: "Dosya Yükle",
      MenuColor: "Menü Rengi",
      CategoryColor: "Kategori Rengi",
      TextColor: "Metin Rengi",
      Font: "Yazı Tipi",
      Date: "Tarih",
      Amount: "Tutar",
      EditInvoiceData: "Fatura / Hakkımızda Bilgilerini Düzenle",
      InvoiceDataRefreshed: "Fatura / Hakkımızda bilgileri güncellendi",
      PasswordChange: "Parola Değiştir",
      PasswordRefreshed: "Parola güncellendi",
      Days: "Günler",
      Remaining: "kalan",
      Scans: "30 Günlük Görüntülemeler",
      DownloadFormats: "SVG, PNG olarak indir",
      YourCode: "Merhaba, QR Kodunuz",
      Description: "Açıklama",
      Price: "Fiyat",
      SubTitle: "Alt Başlık",
      Columns: "Sütunlar",
      Rows: "Satırlar",
      CreateDesign: "Tasarım Oluştur",
      BackgroundImageOptional: "Arka Plan Resmi (isteğe bağlı)",
      DesignTemplates: "Tasarım Şablonları",
      SavedDesigns: "Kaydedilen Tasarımlar",
      Monthly: "Aylık",
      Yearly: "Yıllık",
      PerMonth: "Aylık",
      Subscribe: "Ödeme İşlemine Geç",
      Featured: "Popüler",
      InvoiceName: "İşletme Sahibi",
      Street: "Sokak",
      ZipCode: "Posta Kodu",
      City: "Şehir",
      InvoiceEmail: "Fatura - E-posta",
      NewPassword: "Yeni Parola",
      Delete: "Sil",
      SaveButton: "Şimdi %20 indirimli",
      DesignCreated: "Tasarım oluşturuldu",
      Create: "Oluştur",
      DownloadDesign: "Tasarımı indir",
      Ended: "Süresi doldu",
      Subscription: "Abonelik",
      ProductCreated: "Ürün oluşturuldu",
      CreateProduct: "Ürün oluştur",
      ProductEdited: "Ürün düzenlendi",
      ProductDeleted: "Ürün silindi",
      EditProduct: "Ürün düzenle",
      SettingsRefreshed: "Ayarlar güncellendi",
      EditMenuLayout: "Menü Düzenini Düzenle",
      EditSettings: "Ayarları Düzenle",
      EditLayout: "Düzeni Düzenle",
      Left: "Sol",
      Right: "Sağ",
      Currency: "Para Birimi",
      CurrencyPosition: "Para Birimi Konumu",
      PriceCurrencyIcon: "€",
      DecimalSymbol: "Ondalık Ayırıcı",
      SubscriptionFeatures: "12 Aylık Dijital Menü, Ücretsiz Kurulum Hizmeti, Logo, Sınırsız Yemek ve İçecek, Özelleştirilebilir Menü Tasarımları, Sosyal Medya Bağlantıları, Sınırsız Düzenleme, 24/7 Canlı Destek, Alerjenler ve Katkı Maddeleri, QR Kod Tasarımları",
      PerMonth: "Aylık",
      PerYear: "Yıllık",
      OneTime: "100 Görüntüleme",
      MONTHLY: "Aylık",
      YEARLY: "Senelik",
      ONETIME: "TEK SEFERLİK",
      FREE: "Ücretsiz",
      Pay: "Ödeme İşlemine Geç",
      MandateCreated: "Aboneliğiniz için teşekkür ederiz. Başarılı bir ödeme durumunda fatura e-posta olarak gönderilecektir.",
      MandateError: "Ödeme yöntemi işlenemedi, lütfen tekrar deneyin",
      SocialLinkFacebook: "Facebook Bağlantısı",
      SocialLinkInstagram: "Instagram Bağlantısı",
      SocialLinkGoogle: "Google Bağlantısı",
      QrCodeLink: "QR Kodu Bağlantısı",
      CompanyName: "Şirket Adı",
      Template: "Tasarım Seç",
      Additives: "Katık Maddeler",
      Allergens: "Alerjenler",
      HolderDesigns: "Yazdır",
      AllergensEdited: "Alerjenler güncellendi",
      AdditivesEdited: "Katık maddeler güncellendi",
      NoElements: "Öğe bulunmamaktadır",
      SubscriptionError: "Lütfen hakkımızda bilgilerini tamamlayın",
      SubscriptionInfo: "Ayrıntılı bilgiler için ayrı bir e-posta alacaksınız",
      FeedbackInfo: "Geri bildiriminiz için teşekkür ederiz, güncellemeleri e-posta olarak alacaksınız",
      Priority: "Öncelik",
      Content: "Mesaj",
      Send: "Gönder",
      PriorityWish: "İstek",
      PriorityMiddle: "Orta",
      PriorityImportant: "Önemli",
      PriorityCritical: "Kritik",
      Service: "Kurulum Hizmeti",
      ServiceError: "Lütfen hakkımızda bilgilerini tamamlayın",
      ServiceInfo: "Ayrıntılı bilgiler için ayrı bir e-posta alacaksınız",
      SetupService: "Kurulum Hizmeti",
      SetupServiceRequest: "Gönder",
      SetupServiceDetails: "Dijital menünüzü oluşturuyoruz, sadece mevcut menünüzün resimlerini yüklemeniz gerekmektedir.",
      SetupServicePending: "Menünüzü aldık, kurulumunuz 24 saat sürebilir. QR Kodlu menünüz hazır olduğunda bir e-posta alacaksınız.",
      SetupServiceFinished: "Menünüz başarıyla oluşturuldu.",
      Templates: "Tasarım Seç",
      Activate: "Aktive Et",
      TemplateActivated: "Tasarım aktive edildi. Değişiklikleri görmek için QR Kodu tekrar tarayın.",
      Login: "Giriş",
      PasswordForgotten: "Parolanızı mı unuttunuz",
      Password: "Parola",
      NoAccount: "Hesabınız yok mu?",
      Welcome: "Menuemen'e hoş geldiniz",
      PasswordReset: "Parolayı Sıfırla",
      BackToLogin: "Girişe Dön",
      PasswordResetInfo: "E-posta adresinizi bulursak, yeni giriş bilgileriyle bir bağlantı alırsınız.",
      DescriptionKey: "Kısaltma",
      DescriptionText: "Açıklama",
      OpenMenu: "Önizleme",
      Copy: "Kopyala",
      ProductCopied: "Ürün kopyalandı",
      Inactive: "Pasif",
      FreeLimited: "Ücretsiz",
      EditTemplate: "Tasarımı Düzenle",
      VideoUploading: "Video yükleniyor...",
      VideoUploaded: "Video önizlemeye eklendi",
      TemplateEdited: "Tasarım kaydedildi",
      SignUp: "Ücretsiz Başla",
      AlreadyAccount: "Zaten bir hesabınız var mı?",
      CreateAccount: "Hesap Oluştur",
      SocialMedia: "Sosyal Medya",
      StartChat: "Sohbet Başlat",
      DescriptionChat: "Sorularınız veya geri bildirimleriniz için sağ alt köşede yer alan sohbeti kullanın.",
      SetupServiceCard: "Oluştur",
      GetStarted: "Başlayın",
      ServiceHide: "Bildirimi Gizle",
      SetupServiceCheckoutInfo: "Ödeme işlemine 'Kurulum Hizmeti' ürününü sepete ekleyin.",
      HidePrice: "Fiyatı Gizle"
    },
  },
}

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    interpolation: {
      escapeValue: false,
    },
})

export default i18next;