import React, { useEffect, useState, useMemo } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import axios from 'axios'
import { FileUploader } from "react-drag-drop-files";
import { Store } from 'react-notifications-component';
import './style.css'
import { useTranslation } from "react-i18next";
import FeatherIcon from 'feather-icons-react';
import { 
  Grid, 
  Card,
  CardContent,
  Divider,
  Box,
  Typography,
  FormControlLabel,
  MenuItem,
  Button 
} from '@mui/material';

import {
  FbOrdinaryForm,
  FbDefaultForm,
  FbBasicHeaderForm,
  FbReadonlyForm,
  FbDisabledForm,
  FbLeftIconForm,
  FbRightIconForm,
  FbInputVariants,
} from '../../components/forms/fb-elements/index';
import PageContainer from '../../components/container/PageContainer';
import Breadcrumb from '../../layouts/full-layout/breadcrumb/Breadcrumb';
import CustomTextField from '../../components/forms/custom-elements/CustomTextField';
import CustomCheckbox from '../../components/forms/custom-elements/CustomCheckbox';
import CustomFormLabel from '../../components/forms/custom-elements/CustomFormLabel';
import CustomSelect from '../../components/forms/custom-elements/CustomSelect';

const LayoutPdf = () => {
  const { t } = useTranslation();

  const [keyImageUploader, setKeyImageUploader] = useState("1");
  const fileTypes = ["JPG", "JPEG", "PNG", "GIF"];
  const [file, setFile] = useState(null);
  const [logoUrl, setLogoUrl] = useState("");
  const [menuBackgroundColor, setMenuBackgroundColor] = useState("");
  const [socialLinkFacebook, setSocialLinkFacebook] = useState("");
  const [socialLinkInstagram, setSocialLinkInstagram] = useState("");
  const [socialLinkGoogle, setSocialLinkGoogle] = useState("");
  const [socialLinkTikTok, setSocialLinkTikTok] = useState("");
  const [fullQrCodeLink, setFullQrCodeLink] = useState("");

  const navigate = useNavigate();

  async function load() {
    var response = await axios.get(`${process?.env.REACT_APP_BASE_API_URL}/api/menuemen/menu`);
    console.log(response);

    setLogoUrl(response.data.logoUrl);    
    setMenuBackgroundColor(response.data.menuBackgroundColor);
    setSocialLinkFacebook(response.data.socialLinkFacebook);
    setSocialLinkInstagram(response.data.socialLinkInstagram);
    setSocialLinkGoogle(response.data.socialLinkGoogle);
    setSocialLinkTikTok(response.data.socialLinkTikTok);
    setFullQrCodeLink(response.data.fullQrCodeLink);
  }

  useEffect(() => {
    load();
  }, []);

  const handleImageChange = async (file) => {
    setFile(file);
    const formData = new FormData();
    formData.append("file", file);
    try {
      const response = await axios({
        method: "post",
        url: `${process?.env.REACT_APP_BASE_API_URL}/api/image`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });

      setLogoUrl(response.data);
    } catch(error) {
      console.log(error)
    }
  }
  
  function removeClick() {
    setFile(null);
    setLogoUrl("");
    setKeyImageUploader( keyImageUploader == "1" ? "2" : "1");
    console.log("remove image")
  }

  async function updateSettings() {
   const { response } = await axios.put(
      `${process?.env.REACT_APP_BASE_API_URL}/api/menuemen/menu-pdf`,
      {
        "menuBackgroundColor": menuBackgroundColor,
        "logoUrl": logoUrl,
        "socialLinkFacebook": socialLinkFacebook,
        "socialLinkInstagram": socialLinkInstagram,
        "socialLinkGoogle": socialLinkGoogle,
        "socialLinkTikTok": socialLinkTikTok
      }
    )

    Store.addNotification({
      title: t("SettingsRefreshed"),
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 2000,
        onScreen: true
      }
    });
  }

  return(
    <PageContainer title={t("EditMenuLayout")} textColor="this is innerpage">
      <Grid container spacing={0}>
        <Grid item lg={12} md={12} xs={12}>
          <div>
          {/* ------------------------------------------------------------------------------------------------ */}
          {/* Basic Checkbox */}
          {/* ------------------------------------------------------------------------------------------------ */}
          <Card
            sx={{
              p: 0,
            }}
          >
            <Box
              sx={{ padding: '15px 30px' }}
              display="flex"
              alignItems="center">
              <Box flexGrow={1}>
                <Typography fontWeight="500" variant="h4">
                  {t("EditSettings")}
                </Typography>
              </Box>
            </Box>
            <Divider />
            <CardContent
              sx={{
                padding: '30px',
              }}
            >
              <form>
                <img style={{maxHeight: '150px', borderRadius: '10px', maxWidth: '100%' }} src={logoUrl} />
                
                <CustomFormLabel
                  sx={{
                    mt: 0,
                  }}
                  htmlFor="email-address"
                >
                  Logo
                </CustomFormLabel>
                
                <FileUploader key={keyImageUploader} classes="uploaderFullWidth" label={t("UploadImage")} style={{maxWidth: '100%'}} handleChange={handleImageChange} name="file" types={fileTypes} />
                <Box style={{ float: 'right' }} display="flex" alignItems="flex-end">
                  <Button onClick={removeClick}>
                    <FeatherIcon icon="trash"/>
                  </Button>
                </Box>
                
                <br/>
                

                <CustomFormLabel
                  sx={{
                    mt: 0,
                  }}
                  htmlFor="email-address"
                >
                  {t("MenuColor")}
                </CustomFormLabel>
                <CustomTextField size="small" fullWidth type="color" sx={{ mb: 2 }} value={menuBackgroundColor} onChange={e => setMenuBackgroundColor(e.target.value)} />
                
                <CustomFormLabel
                  sx={{
                    mt: 0,
                  }}
                  htmlFor="email-address"
                >
                  {t("SocialLinkFacebook")}
                </CustomFormLabel>
                <CustomTextField
                  id="email-address"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={socialLinkFacebook}
                  onChange={(e) => setSocialLinkFacebook(e.target.value) }
                />

                <CustomFormLabel
                  sx={{
                    mt: 0,
                  }}
                  htmlFor="email-address"
                >
                  {t("SocialLinkInstagram")}
                </CustomFormLabel>
                <CustomTextField
                  id="email-address"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={socialLinkInstagram}
                  onChange={(e) => setSocialLinkInstagram(e.target.value) }
                />

                <CustomFormLabel
                  sx={{
                    mt: 0,
                  }}
                  htmlFor="email-address"
                >
                  {t("SocialLinkGoogle")}
                </CustomFormLabel>
                <CustomTextField
                  id="email-address"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={socialLinkGoogle}
                  onChange={(e) => setSocialLinkGoogle(e.target.value) }
                />

                <CustomFormLabel
                  sx={{
                    mt: 0,
                  }}
                  htmlFor="email-address"
                >
                  {t("SocialLinkTikTok")}
                </CustomFormLabel>
                <CustomTextField
                  id="email-address"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={socialLinkTikTok}
                  onChange={(e) => setSocialLinkTikTok(e.target.value) }
                />

                <CustomFormLabel
                  sx={{
                    mt: 0,
                  }}
                  htmlFor="email-address"
                >
                  {t("QrCodeLink")}
                </CustomFormLabel>
                <CustomTextField
                  id="email-address"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={fullQrCodeLink}
                  onChange={(e) => {} }
                />
                <div>
                  <Box style={{ float: 'right', paddingBottom: '20px', paddingTop: '10px'}}>
                    <Button onClick={updateSettings} color="primary" variant="contained">
                      {t("Save")}
                    </Button>
                  </Box>
                </div>
              </form>
            </CardContent>
          </Card>
        </div>
        </Grid>
      </Grid>
    </PageContainer>
  )
};

export default LayoutPdf;
