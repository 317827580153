import React, { lazy, useState, useEffect } from 'react';
import axios from 'axios'
import Loadable from './layouts/full-layout/loadable/Loadable';
import { Navigate, useNavigate, useLocation, useParams, useSearchParams, useRoutes } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { useSelector } from 'react-redux';
import RTL from './layouts/full-layout/customizer/RTL';
import ThemeSettings from './layouts/full-layout/customizer/ThemeSettings';
import Router from './routes/Router';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import LogRocket from 'logrocket';

const App = () => {
  const routing = useRoutes(Router);
  const theme = ThemeSettings();
  const customizer = useSelector((state) => state.CustomizerReducer);
  const { search } = useLocation()
  const { token } = useParams();
  const navigate = useNavigate();

  const params = new URLSearchParams(search)
  const authToken = token || params.get('token') || localStorage.getItem("token");
  console.log("token app", authToken);

  if (authToken) {
    localStorage.setItem("token", authToken);
    axios.defaults.headers.common['Authorization'] = authToken;
    LogRocket.identify(authToken);
  }

  useEffect(() => {
    if (!authToken) {
      navigate('/auth/login');
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <ReactNotifications />
      <RTL direction={customizer.activeDir}>
        <CssBaseline />
        {routing}
      </RTL>
    </ThemeProvider>
  );
};

export default App;