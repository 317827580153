export default function formatMoney({ amount = 0, currency = 'EUR', lang = 'de-DE' }) {
    const options = {
      style: 'currency',
      currency,
      minimumFractionDigits: 2,
    }
  
    if (amount % 100 === 0) {
      options.minimumFractionDigits = 0
    }
  
    const formatter = Intl.NumberFormat(lang, options)
  
    return formatter.format(amount)
  }
  