import React, { useState, useEffect } from 'react';
import { Route, Switch, useHistory, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import { Store } from 'react-notifications-component';
import {
  Card,
  Grid,
  CardContent,
  Typography,
  Chip,
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import axios from 'axios'
import FeatherIcon from 'feather-icons-react';
import PageContainer from '../../components/container/PageContainer';
import formatMoney from '../../components/utils/formatMoney'

import payment1 from '../../assets/images/payment/icon-payment-mastercard.png'; 
import payment2 from '../../assets/images/payment/icon-payment-visa.png';
import payment3 from '../../assets/images/payment/icon-payment-bancontact.png';
import payment4 from '../../assets/images/payment/icon-payment-giropay.png';
import payment5 from '../../assets/images/payment/icon-payment-ideal.png'; 
import payment6 from '../../assets/images/payment/icon-payment-sofort.png'; 
import payment7 from '../../assets/images/payment/icon-payment-apple.png'; 

const SUBSCRIPTIONS = [];

const SubscriptionPdf = () => {
  const { t } = useTranslation();
  const [subscriptions, setSubscriptions] = useState(SUBSCRIPTIONS);
  
  async function load() {
    var response = await axios.get(`${process?.env.REACT_APP_BASE_API_URL}/api/subscription/possibilities`);
    setSubscriptions(response.data);
  }

  async function subscribe(url) {
    window.open(url, '_blank', 'noreferrer');
  }

  useEffect(() => {
    load();
  }, [])

  return (
    <PageContainer title={t("Subscription")}>
      <Grid container>
        {subscriptions.map((subscription) => (
          <Grid item xs={12} lg={4} sm={6} key={subscription.interval}>
            <Card sx={{ textAlign: 'center', overflow: 'unset', position: 'relative' }}>
              {subscription.discount > 0 &&
                <Chip
                  sx={{
                    bgcolor: '#FD8800',
                    mt: -4,
                    color: '#fff',
                    fontSize: '14px',
                    display: subscription.interval == "YEARLY" ? 'flex' : 'none',
                    maxWidth: '120px',
                    position: 'absolute',
                    left: '0',
                    right: '0',
                    ml: 'auto',
                    mr: 'auto',
                  }}
                  label={t("Featured") + " -" + subscription.discount + " %"}
                />
              }
              <Typography variant="h4" sx={{ mt: 3 }}>
                {t(subscription.interval)}
              </Typography>
              <Box sx={{ mt: 3 }}>
                <Box sx={{ mt: 3, fontSize: '40px', fontWeight: '600' }}>
                  {formatMoney({ amount: subscription.price })}
                </Box>
                <Typography sx={{ fontSize: '15px', color: 'grey.A200', fontWeight: '400', ml: 1 }}>
                  {subscription.interval == "FREE" ? t("SubscriptionFeaturesFreePdf") : t("SubscriptionFeaturesPdf")}
                </Typography>
              </Box>
              
              {subscription.subscribed == false &&
                <div>
                  <Button onClick={() => subscribe(subscription.url)} variant="contained" sx={{ width: '100%', mt: 4 }}>
                    {t("Subscribe") }
                  </Button>
                  <br/>
                  <br/>
                  <img src={payment1} style={{ height: '24px', marginRight: '5px' }}/>
                  <img src={payment2} style={{ height: '24px', marginRight: '5px' }}/>
                  <img src={payment3} style={{ height: '24px', marginRight: '5px' }}/>
                  <img src={payment4} style={{ height: '24px', marginRight: '5px' }}/>
                  <img src={payment5} style={{ height: '24px', marginRight: '5px' }}/>
                  <img src={payment6} style={{ height: '24px', marginRight: '5px' }}/>
                  <img src={payment7} style={{ height: '24px', marginRight: '5px' }}/>
                </div>
                
              }
            </Card>
          </Grid>
        ))}
      </Grid>
    </PageContainer>
  );
};

export default SubscriptionPdf;
